import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

//ET-DONE date totalCount parameter
const FacilityMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  name: {
    bcKey: 'Name',
    type: 'string',
    required: false,
  },
  name2: {
    bcKey: 'Name_2',
    type: 'string',
    required: false,
  },
  address: {
    bcKey: 'Address',
    type: 'string',
    required: false,
  },
  address2: {
    bcKey: 'Address_2',
    type: 'string',
    required: false,
  },
  city: {
    bcKey: 'City',
    type: 'string',
    required: false,
  },
  county: {
    bcKey: 'County',
    type: 'string',
    required: false,
  },
  postCode: {
    bcKey: 'Post_Code',
    type: 'string',
    required: false,
  },
  phoneNo: {
    bcKey: 'Phone_No',
    type: 'string',
    required: false,
  },
  phoneNo2: {
    bcKey: 'Phone_No_2',
    type: 'string',
    required: false,
  },
  telexNo: {
    bcKey: 'Telex_No',
    type: 'string',
    required: false,
  },
  faxNo: {
    bcKey: 'Fax_No',
    type: 'string',
    required: false,
  },
  contact: {
    bcKey: 'Contact',
    type: 'string',
    required: false,
  },
  eMail: {
    bcKey: 'E_Mail',
    type: 'string',
    required: false,
  },
  countryCode: {
    bcKey: 'Country_Code',
    type: 'string',
    required: false,
  },
  noSeries: {
    bcKey: 'No_Series',
    type: 'string',
    required: false,
  },
  comment: {
    bcKey: 'Comment',
    type: 'boolean',
    required: false,
  },
  parentFacility: {
    bcKey: 'Parent_Facility',
    type: 'boolean',
    required: false,
  },
  parentFacilityCode: {
    bcKey: 'Parent_Facility_Code',
    type: 'string',
    required: false,
  },
  xCoordinates: {
    bcKey: 'X_Coordinates',
    type: 'string',
    required: false,
  },
  yCoordinates: {
    bcKey: 'Y_Coordinates',
    type: 'string',
    required: false,
  },
  temperatureMeasure: {
    bcKey: 'Temperature_Measure',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  navisionLocation: {
    bcKey: 'Navision_Location',
    type: 'string',
    required: false,
  },
  personnelGroup: {
    bcKey: 'Personnel_Group',
    type: 'string',
    required: false,
  },
  useNavisionItemCost: {
    bcKey: 'Use_Navision_Item_Cost',
    type: 'number',
    required: false,
  },
  autoPostJournal: {
    bcKey: 'Auto_Post_Journal',
    type: 'boolean',
    required: false,
  },
  journalInventoryToNavision: {
    bcKey: 'Journal_Inventory_to_Navision',
    type: 'boolean',
    required: false,
  },
  requireNavisionItems: {
    bcKey: 'Require_Navision_Items',
    type: 'boolean',
    required: false,
  },
  journalNonNavisionItems: {
    bcKey: 'Journal_Non_Navision_Items',
    type: 'boolean',
    required: false,
  },
  consumableJournalTemplate: {
    bcKey: 'Consumable_Journal_Template',
    type: 'string',
    required: false,
  },
  consumableJournalBatch: {
    bcKey: 'Consumable_Journal_Batch',
    type: 'string',
    required: false,
  },
  nonItemConsJnlTemplate: {
    bcKey: 'Non_Item_Cons_Jnl_Template',
    type: 'string',
    required: false,
  },
  nonItemConsJnlBatch: {
    bcKey: 'Non_Item_Cons_Jnl_Batch',
    type: 'string',
    required: false,
  },
  defaultLaborRate: {
    bcKey: 'Default_Labor_Rate',
    type: 'number',
    required: false,
  },
  navItemJournalTemplate: {
    bcKey: 'NAV_Item_Journal_Template',
    type: 'string',
    required: false,
  },
  navItemJournalBatch: {
    bcKey: 'NAV_Item_Journal_Batch',
    type: 'string',
    required: false,
  },
  navItemAutoPost: {
    bcKey: 'NAV_Item_Auto_Post',
    type: 'boolean',
    required: false,
  },
  defaultItemCategory: {
    bcKey: 'Default_Item_Category',
    type: 'string',
    required: false,
  },
  generalJournalTemplate: {
    bcKey: 'General_Journal_Template',
    type: 'string',
    required: false,
  },
  generalJournalBatch: {
    bcKey: 'General_Journal_Batch',
    type: 'string',
    required: false,
  },
  generalJournalAutoPost: {
    bcKey: 'General_Journal_Auto_Post',
    type: 'boolean',
    required: false,
  },
  fixedAssetJournalTemplate: {
    bcKey: 'Fixed_Asset_Journal_Template',
    type: 'string',
    required: false,
  },
  fixedAssetJournalBatch: {
    bcKey: 'Fixed_Asset_Journal_Batch',
    type: 'string',
    required: false,
  },
  otherChargesPostingCode: {
    bcKey: 'Other_Charges_Posting_Code',
    type: 'string',
    required: false,
  },
  postToGeneralJournal: {
    bcKey: 'Post_to_General_Journal',
    type: 'boolean',
    required: false,
  },
  postToFixedAssetJournal: {
    bcKey: 'Post_to_Fixed_Asset_Journal',
    type: 'boolean',
    required: false,
  },
  autoPostNonItems: {
    bcKey: 'Auto_Post_Non_Items',
    type: 'boolean',
    required: false,
  },
  postItems: {
    bcKey: 'Post_Items',
    type: 'boolean',
    required: false,
  },
  postPersonnel: {
    bcKey: 'Post_Personnel',
    type: 'boolean',
    required: false,
  },
  personnelJournalTemplate: {
    bcKey: 'Personnel_Journal_Template',
    type: 'string',
    required: false,
  },
  personnelJournalBatch: {
    bcKey: 'Personnel_Journal_Batch',
    type: 'string',
    required: false,
  },
  personnelJournalAutoPost: {
    bcKey: 'Personnel_Journal_Auto_Post',
    type: 'boolean',
    required: false,
  },
  excludeAddressOnWo: {
    bcKey: 'Exclude_Address_on_WO',
    type: 'boolean',
    required: false,
  },
  zCoordinates: {
    bcKey: 'Z_Coordinates',
    type: 'string',
    required: false,
  },
  blockJob: {
    bcKey: 'Block_Job',
    type: 'boolean',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  company: {
    bcKey: 'Company',
    type: 'string',
    required: false,
  },
  navReturnJournalTemplate: {
    bcKey: 'NAV_Return_Journal_Template',
    type: 'string',
    required: false,
  },
  navReturnJournalBatch: {
    bcKey: 'NAV_Return_Journal_Batch',
    type: 'string',
    required: false,
  },
  navReturnAutoPost: {
    bcKey: 'NAV_Return_Auto_Post',
    type: 'boolean',
    required: false,
  },
  postJobs: {
    bcKey: 'Post_Jobs',
    type: 'boolean',
    required: false,
  },
  jobJournalTemplate: {
    bcKey: 'Job_Journal_Template',
    type: 'string',
    required: false,
  },
  jobJournalBatch: {
    bcKey: 'Job_Journal_Batch',
    type: 'string',
    required: false,
  },
  jobsAutoPost: {
    bcKey: 'Jobs_Auto_Post',
    type: 'boolean',
    required: false,
  },
  postResources: {
    bcKey: 'Post_Resources',
    type: 'boolean',
    required: false,
  },
  resourceJournalTemplate: {
    bcKey: 'Resource_Journal_Template',
    type: 'string',
    required: false,
  },
  resourceJournalBatch: {
    bcKey: 'Resource_Journal_Batch',
    type: 'string',
    required: false,
  },
  resourcesAutoPost: {
    bcKey: 'Resources_Auto_Post',
    type: 'boolean',
    required: false,
  },
  createPurchaseOrder: {
    bcKey: 'Create_Purchase_Order',
    type: 'boolean',
    required: false,
  },
  otherPurchasingGLAccount: {
    bcKey: 'Other_Purchasing_G_L_Account',
    type: 'string',
    required: false,
  },
  consumablePurchGLAccount: {
    bcKey: 'Consumable_Purch_G_L_Account',
    type: 'string',
    required: false,
  },
  ospPurchasingGLAccount: {
    bcKey: 'OSP_Purchasing_G_L_Account',
    type: 'string',
    required: false,
  },
  genProdPostingGroup: {
    bcKey: 'Gen_Prod_Posting_Group',
    type: 'string',
    required: false,
  },
  mcoc: {
    bcKey: 'MCOC',
    type: 'boolean',
    required: false,
  },
  effectiveQuantity: {
    bcKey: 'EffectiveQuantity',
    type: 'number',
    required: false,
  },
  scheduledPos: {
    bcKey: 'ScheduledPOs',
    type: 'number',
    required: false,
  },
  allocatedQuantity: {
    bcKey: 'AllocatedQuantity',
    type: 'number',
    required: false,
  },
  projAvailBalance: {
    bcKey: 'ProjAvailBalance',
    type: 'number',
    required: false,
  },
  qtyonDateMeter: {
    bcKey: 'QtyonDateMeter',
    type: 'number',
    required: false,
  },
  noDateMeter: {
    bcKey: 'NoDateMeter',
    type: 'number',
    required: false,
  },
  qtyonParts: {
    bcKey: 'QtyonParts',
    type: 'number',
    required: false,
  },
  noParts: {
    bcKey: 'NoParts',
    type: 'number',
    required: false,
  },
  leadTime: {
    bcKey: 'LeadTime',
    type: 'string',
    required: false,
  },
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  defaultRequisitionJournal: {
    bcKey: 'Default_Requisition_Journal',
    type: 'string',
    required: false,
  },
  defaultTemplate: {
    bcKey: 'Default_Template',
    type: 'string',
    required: false,
  },
  postSalesShipmentControl: {
    bcKey: 'Post_Sales_Shipment_Control',
    type: 'boolean',
    required: false,
  },
  itemBillingGLReplacement: {
    bcKey: 'Item_Billing_G_L_Replacement',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;
type Facility = ODataObjectMap<typeof FacilityMap>;

const FacilitySchema = generateGraphQlSchema('Facility', FacilityMap);

const FacilityQuery = generateGraphQlQuery('Facilitie', FacilityMap);

export { FacilitySchema, type Facility, FacilityQuery, FacilityMap };

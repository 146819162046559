import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const ConsumableMap = {
  no: {
    required: false,
    bcKey: 'No',
    type: 'string',
  },
  description: {
    required: false,
    bcKey: 'Description',
    type: 'string',
  },
  unitOfMeasureCode: {
    required: false,
    bcKey: 'Unit_of_Measure_Code',
    type: 'string',
  },
  itemCategoryCode: {
    required: false,
    bcKey: 'Item_Category_Code',
    type: 'string',
  },
  ospNo: {
    required: false,
    bcKey: 'OSP_No',
    type: 'string',
  },
  ospItemNo: {
    required: false,
    bcKey: 'OSP_Item_No',
    type: 'string',
  },
  navisionVendor: {
    required: false,
    bcKey: 'Navision_Vendor',
    type: 'string',
  },
  grossWeight: {
    required: false,
    bcKey: 'Gross_Weight',
    type: 'number',
  },
  netWeight: {
    required: false,
    bcKey: 'Net_Weight',
    type: 'number',
  },
  purchaseCost: {
    required: false,
    bcKey: 'Purchase_Cost',
    type: 'number',
  },
  billingType: {
    required: false,
    bcKey: 'Billing_Type',
    type: 'string',
  },
  billingNo: {
    required: false,
    bcKey: 'Billing_No',
    type: 'string',
  },
  useNavisionCost: {
    required: false,
    bcKey: 'Use_Navision_Cost',
    type: 'boolean',
  },
  billingUnitPrice: {
    required: false,
    bcKey: 'Billing_Unit_Price',
    type: 'number',
  },
  shelfNo: {
    required: false,
    bcKey: 'Shelf_No',
    type: 'string',
  },
  class: {
    required: false,
    bcKey: 'Class',
    type: 'string',
  },
  navisionQuantityOnHand: {
    required: false,
    bcKey: 'Navision_Quantity_On_Hand',
    type: 'number',
  },
  costWhenConsumed: {
    required: false,
    bcKey: 'Cost_When_Consumed',
    type: 'number',
  },
  navisionLocationFilter: {
    required: false,
    bcKey: 'Navision_Location_Filter',
    type: 'string',
  },
} as const satisfies ODataMap;
type Consumable = ODataObjectMap<typeof ConsumableMap>;

const ConsumableSchema = generateGraphQlSchema('Consumable', ConsumableMap);

const ConsumableQuery = generateGraphQlQuery('Consumable', ConsumableMap);

export { ConsumableSchema, type Consumable, ConsumableQuery, ConsumableMap };

import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const ItemMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  itemCategoryCode: {
    bcKey: 'Item_Category_Code',
    type: 'string',
    required: false,
  },
  baseUnitOfMeasure: {
    bcKey: 'Base_Unit_of_Measure',
    type: 'string',
    required: false,
  },
  type: {
    bcKey: 'Type',
    type: 'string',
    required: false,
  },
  genProdPostingGroup: {
    bcKey: 'Gen_Prod_Posting_Group',
    type: 'string',
    required: false,
  },
  taxGroupCode: {
    bcKey: 'Tax_Group_Code',
    type: 'string',
    required: false,
  },
  inventoryPostingGroup: {
    bcKey: 'Inventory_Posting_Group',
    type: 'string',
    required: false,
  },
  shelfNo: {
    bcKey: 'Shelf_No',
    type: 'string',
    required: false,
  },
  unitCost: {
    bcKey: 'Unit_Cost',
    type: 'number',
    required: false,
  },
  unitPrice: {
    bcKey: 'Unit_Price',
    type: 'number',
    required: false,
  },
  vendorNo: {
    bcKey: 'Vendor_No',
    type: 'string',
    required: false,
  },
  inventory: {
    bcKey: 'Inventory',
    type: 'number',
    required: false,
  },
  blocked: {
    bcKey: 'Blocked',
    type: 'boolean',
    required: false,
  },
  replenishmentSystem: {
    bcKey: 'Replenishment_System',
    type: 'string',
    required: false,
  },
  globalDimension1Filter: {
    bcKey: 'Global_Dimension_1_Filter',
    type: 'string',
    required: false,
  },
  globalDimension2Filter: {
    bcKey: 'Global_Dimension_2_Filter',
    type: 'string',
    required: false,
  },
  locationFilter: {
    bcKey: 'Location_Filter',
    type: 'string',
    required: false,
  },
  dropShipmentFilter: {
    bcKey: 'Drop_Shipment_Filter',
    type: 'string',
    required: false,
  },
  variantFilter: {
    bcKey: 'Variant_Filter',
    type: 'string',
    required: false,
  },
  lotNoFilter: {
    bcKey: 'Lot_No_Filter',
    type: 'string',
    required: false,
  },
  serialNoFilter: {
    bcKey: 'Serial_No_Filter',
    type: 'string',
    required: false,
  },
  unitOfMeasureFilter: {
    bcKey: 'Unit_of_Measure_Filter',
    type: 'string',
    required: false,
  },
  packageNoFilter: {
    bcKey: 'Package_No_Filter',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Item = ODataObjectMap<typeof ItemMap>;

const ItemSchema = generateGraphQlSchema('Item', ItemMap);

const ItemQuery = generateGraphQlQuery('Item', ItemMap);

export { ItemSchema, type Item, ItemQuery, ItemMap };

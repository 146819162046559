import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  get<T>(
    partial: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          }
        | undefined;
    }
  ) {
    return this.httpClient.get<T>(`${environment.baseWS}${partial}`, options);
  }

  post<T>(partial: string, body?: any) {
    return this.httpClient.post<T>(`${environment.baseWS}${partial}`, body);
  }

  delete<T>(partial: string) {
    return this.httpClient.delete<T>(`${environment.baseWS}${partial}`);
  }
}
